<template>
    <v-container fluid :style="{ background: $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode }">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-row v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
            <v-col align="right" >
                <v-icon class="px-1" @click="editDialog = !editDialog" :color="$store.getters.getColorPalette().submitbtnColor" >mdi-pencil</v-icon>
                <DeleteConfirmation v-on:confirm="deleteShiftUsageLog()" title="Delete Confirmation" description="Are you sure you want to delete this?">
                    <v-icon class="pr-2" :color="$store.getters.getColorPalette().cancelbtnColor">mdi-delete</v-icon>
                </DeleteConfirmation>
            </v-col>
        </v-row>
        <transition name="slide-fade">
            <v-card v-model="editDialog" class="mx-7 pb-5" v-if="editDialog" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                <CreateShiftMachineUsageLog :shiftMachineUsageLog="shiftMachineUsageLog" v-on:close="editDialog = false" />
            </v-card>
        </transition>
        <v-card 
            :style="{ border: `1px solid ${$store.getters.getColorPalette().accentCode}`, borderRadius: '5px' }"
            class="mx-7 my-5" 
            transition="scroll-x-reverse-transition" 
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode"
        >
            <WriteShiftMachineUsageLog :shiftMachineUsageLog="shiftMachineUsageLog" />
        </v-card>
        <v-card 
            :style="{ border: `1px solid ${$store.getters.getColorPalette().accentCode}`, borderRadius: '5px' }"
            class="mx-7 my-5" 
            transition="scroll-x-reverse-transition" 
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode"
        >
            <v-form ref="usageForm" v-model="valid">
                <v-row class="pa-5">
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-menu v-if="!loading" v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="dateRangeText" :label="$store.getters.getTextMap().dates" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :rules="objectRules" outlined dense></v-text-field>
                            </template>
                            <v-date-picker v-model="form.date" range></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col align-self="center">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-btn class="mt-n6" v-if="!loading" :color="$store.getters.getColorPalette().accentCode" outlined small @click="fetchLogs">{{$store.getters.getTextMap().view_logs}}</v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <!-- <v-form ref="filterForm" v-model="filterValid">
                <v-row class="px-8">
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-select 
                            v-if="!filterLoading" 
                            v-model="filterForm.param" 
                            :items="filterParamOptions" 
                            :label="$store.getters.getTextMap().param"
                            :rules="objectRules" 
                            outlined 
                            dense 
                            required 
                            item-text="label" 
                            item-value="value" 
                            clearable>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-select 
                            v-if="!filterLoading" 
                            v-model="filterForm.operator" 
                            :items="operators" 
                            item-text="label"
                            item-value="value" 
                            :label="$store.getters.getTextMap().operator"
                            :rules="objectRules" required
                            :dark="$store.getters.getColorPalette().isDark" clearable
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" 
                            outlined 
                            dense>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-text-field 
                            :loading="filterLoading" 
                            v-model="filterForm.value" 
                            :counter="11" 
                            :rules="objectRules"
                            :label="$store.getters.getTextMap().target_value"
                            required 
                            outlined 
                            dense>
                        </v-text-field>
                    </v-col>
                    <v-col align-self="center">
                        <v-progress-circular indeter minate v-if="filterLoading" color="primary"></v-progress-circular>
                        <v-btn 
                            v-if="!filterLoading" 
                            class="mt-n6" 
                            :color="$store.getters.getColorPalette().accentCode" 
                            outlined
                            small 
                            @click="addFilter"
                        >
                            {{$store.getters.getTextMap().add_filter}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form> -->
        </v-card>
        <v-row v-if="filters.length > 0">
            <v-col>
                <span><strong>Filters:</strong></span>
                <v-chip 
                    class="ma-2" 
                    close 
                    @click:close="deleteFilter(filter.key)" 
                    color="indigo" 
                    text-color="white"
                    v-for="filter in filters" :key="filter.key">
                    {{ filter.param }} {{ filter.operator }} {{ filter.value }}
                </v-chip>
            </v-col>
        </v-row>
        <v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular>
        <v-row v-if="graphData.length > 0">
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table 
                        :headers="tableHeaders" 
                        :items="filteredData" 
                        item-key="index" 
                        :search="search"
                        :single-expand="true" 
                        show-expand 
                        :expanded.sync="expanded" 
                        :loading="loading"
                        loading-text="Loading... Please wait" 
                        :sort-by.sync="sortBy" 
                        :sort-desc.sync="sortDesc"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>{{$store.getters.getTextMap().shift_based_machine_usage_log}} - {{ dateRangeText }}</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-btn 
                                    v-if="graphData.length > 0" 
                                    outlined :color="$store.getters.getColorPalette().downloadbtnColor"
                                    small @click="download"
                                >
                                    {{$store.getters.getTextMap().download_table}}
                                </v-btn>
                                <a ref="csvDownload" hidden></a>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.from_timestamp="{ item }">
                            <strong>{{ toReadableTime(item.production_start) }}</strong>
                        </template>
                        <template v-slot:item.production_time="{ item }">
                            <strong>{{ toReadableTime(item.production_time) }}</strong>
                        </template>
                        <template v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-dialog v-model="editShiftMachineUsageLogData" v-if="editShiftMachineUsageLogData" hide-overlay misc>
                                    <v-card 
                                        :color="$store.getters.getColorPalette().backgroundColorCode"
                                        :dark="$store.getters.getColorPalette().isDark" 
                                        class="pt-5"
                                    >
                                        <v-card-text>
                                            <div>
                                                <EditWrittenShiftMachineUsageLog 
                                                    :shiftMachineUsageLog="shiftMachineUsageLogProp" 
                                                    :selectedItem="selectedItem"
                                                    @close="closeDialog" 
                                                    @update="refreshLogs" 
                                                />
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                                <v-btn 
                                    class="mx-1" 
                                    :color="$store.getters.getColorPalette().accentCode" 
                                    small 
                                    text 
                                    outlined
                                    v-on:click="editShiftMachineUsageLogDatas(item)"
                                >
                                    {{$store.getters.getTextMap().edit_log}}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-pencil</v-icon>
                                </v-btn>
                                <DeleteConfirmation 
                                    v-on:confirm="deleteLogData(item)" 
                                    title="Delete Confirmation"
                                    description="Are you sure you want to delete this Log?"
                                >
                                    <v-btn class="mx-1" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete_log}}
                                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                                    </v-btn>
                                </DeleteConfirmation>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import CreateShiftMachineUsageLog from '@/components/crud_components/loggers/CreateShiftMachineUsageLog'
import WriteShiftMachineUsageLog from '@/components/crud_components/loggers/WriteShiftMachineUsageLog'
import EditWrittenShiftMachineUsageLog from '@/components/crud_components/loggers/EditWrittenShiftMachineUsageLog'
import moment from 'moment'
import axios from 'axios'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'
// import { applyPlugin } from 'jspdf-autotable'
// applyPlugin(jsPDF)
export default {
    name: 'ShiftMachineUsageLogDetails',
    props: ['shiftMachineUsageLog'],
    components: {
        CreateShiftMachineUsageLog,
        InfoAlert,
        DeleteConfirmation,
        WriteShiftMachineUsageLog,
        EditWrittenShiftMachineUsageLog
    },
    mounted() {
        this.init()
        this.form.date = []
        this.form.date[0] = moment().subtract(15, 'd').format('YYYY-MM-DD')
        this.form.date[1] = moment().add(15, 'd').format('YYYY-MM-DD')
        this.fetchLogs()
    },
    data() {
        return {
            sortBy: 'from_timestamp',
            sortDesc: false,
            multiple: false,
            isActive: false,
            editDialog: false,
            form: {
                date: null,
                shift_id: null
            },
            groupBy: null,
            expanded: [],
            search: '',
            valid: false,
            groupValid: false,
            menu: false,
            meta: {},
            graphData: [],
            groupedData: [],
            showDismissibleAlert: false,
            loading: false,
            info: '',
            filters: [],
            filterLoading: false,
            filterValid: false,
            selectedItem: null,
            dialog: false,
            filterForm: {
                param: null,
                operator: '==',
                value: null
            },
            operators: [
                { label: 'Greater than', value: '>' },
                { label: 'Lesser than', value: '<' },
                { label: 'Equals to', value: '==' },
                { label: 'Greater than equal to', value: '>=' },
                { label: 'Lesser than equal to', value: '<=' },
            ],
            objectRules: [
                v => !!v || 'Required',
            ],
            headers: [
                {
                    text: 'Date',
                    align: 'start',
                    filterable: false,
                    value: 'date',
                },
            ],
            editShiftMachineUsageLogData: false,
            shiftMachineUsageLogProp: null
        }
    },
    computed: {
        dateRangeText() {
            if (this.form.date == null) {
                return null
            }
            return this.form.date.join(' - ')
        },
        fromTimeStamp() {
            if (this.form.date) {
                if (this.form.date.length > 1) {
                    let dt = this.form.date[0].toString()
                    // if (Number(moment(dt, 'YYYY-MM-DD').format("X")) > Number(moment(this.form.date[0].toString(), 'YYYY-MM-DD').format("X"))) {
                    //     dt = this.form.date[1].toString()
                    // }
                    return moment(dt, 'YYYY-MM-DD').format("YYYY-MM-DD");
                } else {
                    return moment(this.form.date[0].toString(), 'YYYY-MM-DD');
                }
            } 
            else {
                return null
            }
        },
        toTimeStamp() {
            if (this.form.date) {
                if (this.form.date.length > 1) {
                    let dt = this.form.date[1].toString()
                    // if (Number(moment(this.form.date[0].toString(), 'YYYY-MM-DD').format("X")) > Number(moment(dt, 'YYYY-MM-DD').format("X"))) {
                    //     dt = this.form.date[0].toString()
                    // }
                    return moment(dt, 'YYYY-MM-DD').format("YYYY-MM-DD");
                } else {
                    return moment(this.form.date[1].toString(), 'YYYY-MM-DD');
                }
            }else {
                return null
            }
        },
        filteredData() {
            let filteredData = this.graphData
            // for (let i of this.filters) {
            //     let filterFunc = () => {}
            //     switch (i.operator) {
            //         case '>=': filterFunc = (data) => { return data[i.param] >= i.value };
            //         break;
            //         case '>': filterFunc = (data) => { return data[i.param] > i.value };
            //         break;
            //         case '==': filterFunc = (data) => { return data[i.param] == i.value };
            //         break;
            //         case '<=': filterFunc = (data) => { return data[i.param] <= i.value };
            //         break;
            //         case '<': filterFunc = (data) => { return data[i.param] < i.value };
            //         break;
            //     }
            //     filteredData = filteredData.filter(filterFunc);
            // }
            return filteredData
        },  
        metaItems() {
            if (this.meta) {
            return Object.values(this.meta)
            }
            return []
        },
        filterParamOptions() {
            let op = []
            for (let i in this.meta) {
                op.push({
                    label: i,
                    value: i.label
                })
            }
            return op
        },
        tableHeaders() {
            let headers = [
                {
                    text: 'Machine',
                    align: 'start',
                    filterable: true,
                    value: 'machine',
                },
                {
                    text: 'Date',
                    filterable: true,
                    value: 'date',
                },
                {
                    text: 'From Time',
                    filterable: true,
                    value: 'from_time',
                },
                {
                    text: 'Production Start',
                    filterable: true,
                    value: 'production_start',
                },
                {
                    text: 'Production End',
                    filterable: true,
                    value: 'production_end',
                },
                {
                    text: 'SKU',
                    filterable: true,
                    value: 'sku',
                },
                {
                    text: 'Logged Running Time',
                    filterable: true,
                    value: 'logged_running',
                },
                {
                    text: 'Logged Idle Time',
                    filterable: true,
                    value: 'logged_idle',
                },
                {
                    text: 'Logged Down Time',
                    filterable: true,
                    value: 'logged_downtime',
                },
                {
                    text: 'Machine Operator',
                    filterable: true,
                    value: 'machine_operator',
                },
                {
                    text: 'Total Production',
                    filterable: true,
                    value: 'total_production',
                },
                {
                    text: 'Good Production',
                    filterable: true,
                    value: 'good_count',
                },
                {
                    text: 'Rejected',
                    filterable: true,
                    value: 'rejected',
                },
               
               
            ]
            for (let i of this.metaItems) {
                headers.push({ text: i.label, filterable: true,value: i.label })
            }
                headers.push({ text: "Entered By",filterable: true, value: 'userid' })
                headers.push({
                    text: 'Energy',
                    filterable: true,
                    value: 'energy',
                })
                headers.push({
                    text: 'Energy Intensity',
                    filterable: true,
                    value: 'energy_intersity',
                })
                headers.push({
                    text: 'SEC',
                    filterable: true,
                    value: 'sec',
                })
                headers.push({
                    text: 'KGCO2',
                    filterable: true,
                    value: 'kgco2',
                })
                headers.push({
                    text: 'Specific Carbon Emission',
                    filterable: true,
                    value: 'specific_carbon_emission',
                })


            return headers
        }
    },
    methods: {
        getMachineName(machine_id){
            let machineDetails = this.$store.getters.getMachineById(machine_id)
            return machineDetails.name?machineDetails.name:machine_id
        },
        getSkuName(sku_id){
            let skuDetails = this.$store.getters.getSKUById(sku_id)
            return skuDetails.sku_name?skuDetails.sku_name:sku_id
        },
        init() {
            if (this.shiftMachineUsageLog) {
                this.meta = Object.assign({}, this.shiftMachineUsageLog.meta)
                this.updateTableHeader()
            }
        },
        refreshLogs() {
            this.fetchLogs();
        },
        closeDialog() {
            this.editShiftMachineUsageLogData = !this.editShiftMachineUsageLogData
        },
        toReadableTimeFromEpoch(timestamp) {
            if (isNaN(timestamp)) {
                return null
            }
            return moment.unix(Number(timestamp)).format('dddd, MMMM Do YYYY, h:mm:ss a')
        },
        toReadableTime(timestamp) {
            if(timestamp){
                return moment(timestamp).format('dddd, MMMM Do YYYY, h:mm:ss a')
            }
            return '-'
        },
        deleteShiftUsageLog() {
            axios.post(this.$store.state.api + 'deleteShiftMachineUsageLog', this.shiftMachineUsageLog, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    this.info = 'Logger Deleted successfully';
                    this.showDismissibleAlert = true;
                    this.$store.dispatch('refreshShiftMachineUsageLogs')
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false
            }).catch(err => {
                console.log(err)
                this.showDismissibleAlert = true
                this.info = err
                this.loading = false
            })
        },
        editShiftMachineUsageLogDatas(item) {
            this.selectedItem = item;
            this.shiftMachineUsageLogProp = { ...this.shiftMachineUsageLog };
            this.editShiftMachineUsageLogData = !this.editShiftMachineUsageLogData
        },
        download() {
            // let pdf = new jsPDF();
            let header = []
            let keyMapping = {};
            this.headers.forEach(val => {
            header.push(val.text);
                keyMapping[val.text] = val.value;
            })
            let body = []
            for (let i of this.filteredData) {
                const values = header.map(heading => {
                    const key = keyMapping[heading];
                    let value = i[key];
                    if (key === "production_start" || key === "production_time") {
                        value = this.toReadableTime(value);
                    }
                    const escaped = ('' + value)
                    return `"${escaped}"`;
                })
                body.push(values)
            }
            // pdf.autoTable({
            //     head: [header],
            //     body: body
            // })
            // pdf.save(this.shiftMachineUsageLog.name + '_' + this.dateRangeText + '.pdf');
            let csvHeader = header.join(',') + "\n"
            let csv = csvHeader
            csv += body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });
            let csvUrl = URL.createObjectURL(csvData);
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = this.shiftMachineUsageLog.name + '_' + this.dateRangeText + '.csv';
            hiddenElement.click();
        },
        updateTableHeader() {
            this.headers = this.tableHeaders
        },
        fetchLogs() {
            this.$refs.usageForm.validate()
            if (this.valid) {
                this.loading = true
                let payload = {
                    shift_id: this.shiftMachineUsageLog.shift_id,
                    from_date: this.fromTimeStamp,
                    to_date: this.toTimeStamp
                }
                let endpoint = 'getShiftMachineUsageLogBetweenTimestamp'
                axios.post(this.$store.state.api + endpoint, payload, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.updateTableHeader()
                        let data = []
                        for (let i of response.data.data) {
                            let temp = {
                                shift_id:i.shift_id,
                                machine:this.getMachineName(i.machine_id),
                                machine_id:i.machine_id,
                                from_time:i.from_time,
                                date:moment(i.date).format("YYYY-MM-DD"),
                                // production_start:i.production_start,
                                // production_end:i.production_end,
                                production_start:moment(i.production_start).format("YYYY-MM-DD hh:mm"),
                                production_end:moment(i.production_end).format("YYYY-MM-DD hh:mm"),
                                sku:this.getSkuName(i.sku_id),
                                logged_running:i.logged_running,
                                logged_idle:i.logged_idle,
                                logged_downtime:i.logged_downtime,
                                machine_operator:i.machine_operator,
                                good_count:i.good_count,
                                total_production:i.total_production,
                                rejected:i.rejected,
                                userid:i.userid,
                                energy_intersity:i.energy_intersity,
                                energy:i.energy,
                                kgco2:i.kgco2,
                                sec:i.sec,
                                specific_carbon_emission:i.specific_carbon_emission,
                                ...i.meta_data
                            }
                            data.push(temp)
                        }
                        this.graphData = data
                        if (this.graphData.length == 0) {
                            this.info = "No data to show";
                            // this.showDismissibleAlert = true;
                        }
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading = false
                    }
                this.loading = false
                })
                .catch(err => {
                    console.log(err)
                    this.showDismissibleAlert = true
                    this.info = err
                    this.loading = false
                })
            }
        },
        addFilter() {
            this.$refs.filterForm.validate()
            if (this.filterValid) {
                let d = Object.assign({}, this.filterForm)
                d.key = Number(moment().format("X"))
                this.filters.push(d)
                this.$refs.filterForm.reset()
            }
        },
        deleteFilter(key) {
            this.filters = this.filters.filter(
                function (data) { return data.key != key }
            );
        },
        deleteLogData(logData) {
            this.loading = false
            let payload ={
                shift_id:this.shiftMachineUsageLog.shift_id,
                machine_id:logData.machine_id,
                from_time:logData.from_time,
                date:logData.date,
            }
            axios.post(this.$store.state.api + 'deleteShiftMachineUsageLogData', payload, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
            .then(response => {
                if (response.data.status === 'success') {
                    this.info = 'Entry Deleted successfully';
                    this.showDismissibleAlert = true;
                    this.fetchLogs()
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false
            }).catch(err => {
                console.log(err)
                this.showDismissibleAlert = true
                this.info = err
                this.loading = false
            })
        },
    }
}
</script>
<style scoped>
.slide-fade-enter-active {
    transition: all .8s ease;
}.slide-fade-leave-active {
    transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter,.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>
  